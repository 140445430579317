import React from 'react';
import { Component } from 'react';

import { Row, Col, Container, Form, Input } from 'reactstrap';
import httpService from '../../../services/httpService';

import classes from './PersonalDetails.module.css';
import authService from '../../../services/authService';

import Loader from 'components/UI/Loader/Loader';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';


class personalDetails extends React.Component{
  constructor(props){
    super(props);
      this.state={
        first_name:'',
        last_name:'',
        company:'',
        vat_no:'',
        company_url:'',
        email:'',
        address:'',
        city:'',
        country:'',
        zipcode:'',
        state:'',
        industry:'',
        video_specialization:'',
        software_skills:'',
        custom_preferences:'',
        education:'',
        description:'',
        company_logo: null,
        image:null,
        photo:null,
        companylogo:null,
        loading: true,
        showModal: false
    };
  }
    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      })
    };
    handleImageChange = (e) => {
      //console.log('eee->',e);
      this.setState({
        [e.target.name]: e.target.files[0],
        photo : URL.createObjectURL(e.target.files[0]) 
      })
    };
    handleLogoChange = (e) => {
      //console.log('eee->',e);
      this.setState({
        [e.target.name]: e.target.files[0],
        companylogo : URL.createObjectURL(e.target.files[0])
      })
    };

    handler = {
      toggle: () => !this.props.isOpen,
      
      handleSubmit : (e) => {
        e.preventDefault();
        //this.setState({loading: true});
        
        this.setState({showModal:true});
        
  
      }
    }

    SubmitDetail = (e) => {
      e.preventDefault();
      this.setState({loading: true});
      let form_data = new FormData();
      if(this.state.image!==null){
        form_data.append('image', this.state.image,  this.state.image.name);
      }
      if(this.state.company_logo!==null){
        form_data.append('company_logo', this.state.company_logo, this.state.company_logo.name );
      }
      form_data.append('first_name', this.state.first_name);
      form_data.append('last_name', this.state.last_name);
      form_data.append('company', this.state.company);
      form_data.append('vat_no', this.state.vat_no);
      form_data.append('company_url', this.state.company_url);
      form_data.append('email', this.state.email);
      form_data.append('address', this.state.address);
      form_data.append('city', this.state.city);
      form_data.append('country', this.state.country);
      form_data.append('zipcode', this.state.zipcode);
      form_data.append('state', this.state.state);
      form_data.append('industry', this.state.industry);
      form_data.append('video_specialization', this.state.video_specialization);
      form_data.append('software_skills', this.state.software_skills);
      form_data.append('custom_preferences', this.state.custom_preferences);
      form_data.append('education', this.state.education);
      form_data.append('description', this.state.description);
      this.setState({showModal:false});
      let url = '/user/edit/';

      const config = {
        headers: {
          'content-type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
        }
      }
      httpService.put('/user/edit/',form_data,config).then(response => {
          
          console.log(response);
      })
      
    }

    CancelDetail = (e) => {
      this.setState({loading: false});
      this.setState({showModal:false});
    }

    
    componentDidMount() {
      
      httpService.get('/user/'+ authService.getUserId())
      .then(res => {
        //console.log("response-->", res);
        this.setState({first_name:res.data.user.first_name});
        this.setState({last_name:res.data.user.last_name});
        this.setState({company:res.data.company});
        this.setState({vat_no:res.data.vat_no});
        this.setState({company_url:res.data.company_url});
        this.setState({email:res.data.user.email});
        this.setState({address:res.data.address});
        this.setState({city:res.data.city});
        this.setState({country:res.data.country});
        this.setState({zipcode:res.data.zipcode});
        this.setState({state:res.data.state});
        this.setState({industry:res.data.industry});
        this.setState({video_specialization:res.data.video_specialization});
        this.setState({software_skills:res.data.software_skills});
        this.setState({custom_preferences:res.data.custom_preferences});
        this.setState({education:res.data.education});
        this.setState({description:res.data.description});
        this.setState({companylogo:`https://stag.videobzz.com:9000` + res.data.company_logo});
        this.setState({photo:`https://stag.videobzz.com:9000`+ res.data.image});
        this.setState({description:res.data.description});
        this.setState({loading: false});
      }).catch(error => {
          console.log(error);
      });
    }

    showModalHandler = (event) =>{
      //console.log('hello');
      this.setState({showModal:true});
    }

    hideModalHandler = (event) =>{
      //console.log('hello');
      this.setState({showModal:false});
    }


  render(){
  return (
    <div className={classes.PersonalDetails}>
      <Loader loading={this.state.loading} />
      <Container>
      {/*<div>
      <button >Preview</button>
      </div>*/}
        {/*<div><p className={classes.PreviewHead}>  Preview</p> </div>*/}
        <Row>
          <Col sm="12" md={{ size: 7 }}>
            <Form className={classes.PersonalDetailsForm} onSubmit={this.handler.handleSubmit}>
              <div>
                <button type="submit" >Preview</button>
                
              </div>
              <Input type="text" defaultValue={this.state.first_name} name="first_name" onChange={this.handleChange} placeholder="Name" bsSize="sm" className={classes.Personelinput} />
              <Input type="text" defaultValue={this.state.last_name} name="last_name" onChange={this.handleChange} placeholder="Surname" bsSize="sm" className={classes.Personelinput} />

              <Input type="text" defaultValue={this.state.company} name="company" onChange={this.handleChange} placeholder="Company" bsSize="sm" className={classes.Personelinput} />
              <Input type="text" defaultValue={this.state.vat_no} name="vat_no" onChange={this.handleChange} placeholder="vat no." bsSize="sm" className={classes.Personelinput} />

              <Input type="url" defaultValue={this.state.company_url} name="company_url" onChange={this.handleChange} placeholder="url" bsSize="sm" className={classes.Personelinput} />

              <Input type="email" defaultValue={this.state.email} name="email" onChange={this.handleChange} placeholder="Email" bsSize="sm" className={classes.Personelinput} />

              <Input type="textarea" defaultValue={this.state.address} name="address" onChange={this.handleChange} placeholder="Address" rows="4" bsSize="sm" className={classes.Personelinput} />
              <Input type="text" defaultValue={this.state.city} name="city" onChange={this.handleChange} placeholder="City" bsSize="sm" className={classes.Personelinput} />
              <Input type="text" defaultValue={this.state.country} name="country" onChange={this.handleChange} placeholder="Country" bsSize="sm" className={classes.Personelinput} />
              <Input type="text" defaultValue={this.state.zipcode} name="zipcode" onChange={this.handleChange} placeholder="Zipcode" bsSize="sm" className={classes.Personelinput} />
              <Input type="text" defaultValue={this.state.state} name="state" onChange={this.handleChange} placeholder="State" bsSize="sm" className={classes.Personelinput} />

              <Input type="text" defaultValue={this.state.industry} name="industry" onChange={this.handleChange} placeholder="Industry" bsSize="sm" className={classes.Personelinput} />
              <Input type="text" defaultValue={this.state.video_specialization} name="video_specialization" onChange={this.handleChange} placeholder="Video Specialization" bsSize="sm" className={classes.Personelinput} />
              <Input type="text" defaultValue={this.state.software_skills} name="software_skills" onChange={this.handleChange} placeholder="Software skills" bsSize="sm" className={classes.Personelinput} />
              <Input type="text" defaultValue={this.state.custom_preferences} name="custom_preferences" onChange={this.handleChange} placeholder="Creation preferences" bsSize="sm" className={classes.Personelinput} />
              <Input type="text" defaultValue={this.state.education} name="education" onChange={this.handleChange} placeholder="Education" bsSize="sm" className={classes.Personelinput} />
              
              <div>   <Input type="textarea" defaultValue={this.state.description} name="description" id="exampleText" onChange={this.handleChange} placeholder="Tell about your style" rows="7" className={classes.Personelinput} />    </div>

            </Form>
          </Col>
          <Col sm="12" md={{ size: 5 }}>

            <div className={classes.Uploadsquare}>
              <div className={classes.Squares}><p>LOGO</p>
                <div className={classes.UploadBtn}>
                  <button className={classes.MyBtn}>Upload</button>
                  <input type="file" defaultValue={this.state.company_logo} accept="image/png, image/jpeg" name="company_logo" onChange={this.handleLogoChange}/>
                  <img src={this.state.companylogo} width="100"/>
                </div></div>

              <div className={classes.Squares}><p>PHOTO</p>
                <div className={classes.UploadBtn}>
                  <button className={classes.MyBtn}>Upload</button>
                  <input type="file"  defaultValue={this.state.image} accept="image/png, image/jpeg" name="image" onChange={this.handleImageChange} />
                  <img src={this.state.photo} width="100"/>
                </div></div>

            </div>

            <div>
              <Input type="textarea" defaultValue={this.state.description} name="description" id="exampleText" onChange={this.changeHandler} placeholder="Your story" rows="15" className={classes.Personelinput} />
            </div>

            </Col>
        </Row>
      </Container>
      <Modal  
                size="md"
                isOpen={this.state.showModal}
                backdrop={true}
                fade={false}
                toggle={this.hideModalHandler}
                contentClassName={classes.Test} >
                {/* <ModalHeader  className={classes.MHead} toggle={this.props.closed}>

                </ModalHeader> */}
                <ModalBody >
                  <div className={classes.SeriesLeft}>
                    {/* <UserProfileImg /> */}
                    <div className={classes.UserProfileImg}>
                    <img  src={this.state.photo}/>
                    </div>
                    <p className={classes.ProfileName}>{this.state.first_name} {this.state.last_name}</p>
                   <hr/>
                    <div className={classes.ProfileDescription}>
                    <p>
                        <span className="float-left">Company: {this.state.company}</span>
                        <span className="float-right">Logo: <img  src={this.state.companylogo} height="50" width="50"/></span><br />
                        <span className="float-left">Company Url: {this.state.company_url}</span><br/>
                        <span className="float-left">Vat No.: {this.state.vat_no}</span><br/>
                        <span className="float-left">Email: {this.state.email}</span>
                        
                    </p>
                    </div>
                    <hr />
                    <div className={classes.ProfileDescription}>
                    <p>
                        <span className="float-left">Address: {this.state.address}</span>
                        <span className="float-right">City: {this.state.city}</span><br/>
                        <span className="float-left">Country: {this.state.country}</span>
                        <span className="float-right">Zipcode: {this.state.zipcode}</span><br />
                        <span className="float-left">State: {this.state.state}</span>
                    </p>
                    </div>
                    <hr />
                    <div className={classes.ProfileDescription}>
                    <p>
                        <span className="float-left">Industry: {this.state.industry}</span>
                        <span className="float-right">Video Specialization: {this.state.video_specialization}</span><br/>
                        <span className="float-left">Software Skills: {this.state.software_skills}</span>
                        <span className="float-right">Custom Preferences: {this.state.custom_preferences}</span><br />
                        <span className="float-left">Education: {this.state.education}</span>
                        <span className="float-right">Description: {this.state.description}</span>
                    </p>
                    </div><hr/>
                    <div>
                      <p>
                      <span className="float-left" style={{paddingLeft: '10px', paddingBottom: '10px'}}>
                        <button type="submit" onClick={this.SubmitDetail}>Save</button>
                        </span>
                      <span className="float-right" style={{paddingRight: '10px', paddingBottom: '10px'}}>
                        <button onClick={this.CancelDetail}>Cancel</button>
                        </span>
                      </p>
                    </div>
                  </div>
                           
                </ModalBody>
            </Modal> 
    </div>

    
  )
}
}

export default personalDetails;
