import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import classes from './NavItemProfile.module.css';
import UserProfileImg from 'components/UI/UserProfileImg/UserProfileImg';

const NavItemProfile = (props) => {

  let navProfileLinks = [];

  // if (props.isLoggedIn) {
  navProfileLinks.push({ link: "/my-profile", name: "Profile", fa_icon: faCog });
  navProfileLinks.push({ link: "/collections", name: "Collections" });
  navProfileLinks.push({ link: "/setting", name: "Setting" });
  navProfileLinks.push({ link: "/user", name: "Contributor Admin" });
  // navProfileLinks.push({ link: "/logout", name: "Logout" });
  navProfileLinks.push({ is_devider: true });
  // }

  navProfileLinks.push({ link: "/faq", name: "FAQ" });
  navProfileLinks.push({ link: "/tutorials", name: "Tutorials" });
  navProfileLinks.push({ link: "/imprint-and-terms", name: "Imprint & terms" });
  navProfileLinks.push({ link: "/contributor-guideline", name: "Contributor guideline" });
  // navProfileLinks.push({ link: "/user", name: "Contributor Admin" });

  let naveProfileItems = [];
  naveProfileItems = navProfileLinks.map((item, i) => {
    return (
      item.is_devider ?
        <DropdownItem key={"m" + item.link} divider className={classes.Divider} />
        :
        <DropdownItem key={"m" + item.link}>
          <NavLink
            key={item.link}
            className={["dropdown-item", classes.DropdownItem].join(" ")}
            exact
            to={item.link}
            activeClassName={classes.active}
          >
            {item.name} {item.fa_icon ? <FontAwesomeIcon className={classes.CogIcon} icon={item.fa_icon}></FontAwesomeIcon> : ''}
          </NavLink>
        </DropdownItem>
    )


  });

  return (
    <UncontrolledDropdown nav inNavbar className={classes.NavItemProfile}>
      <DropdownToggle nav>
        <UserProfileImg />
        
        <p>{(localStorage.getItem("__vz__name")) ? (localStorage.getItem("__vz__name")):(localStorage.getItem("__vz__user"))}</p>
      </DropdownToggle>
      <DropdownMenu right className={classes.DropDiv}>
        {naveProfileItems}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
export default NavItemProfile
