import React, { Component } from 'react';
import { withRouter } from "react-router";
import {
    Col, Modal, ModalHeader, ModalBody,
    Button
} from 'reactstrap';
import { connect } from 'react-redux';
import axios from 'axios'

import FacebookLogin from 'react-facebook-login';
// import LinkedIn from 'react-linkedin-login'
import { LinkedIn } from 'react-linkedin-login-oauth2';

import classes from './AuthModal.module.css';
import errorClasses from 'components/UI/Error/Error.module.css';

import Loader from 'components/UI/Loader/Loader';
import Input from 'components/UI/Input/Input';
import { updateObject, checkValidity } from 'utils/utility';
import httpService from 'services/httpService';
// import LinkedInPage from '../Auth/LinkedIn';
import {
    updateProfileDetails
} from 'store/actions';

const mapStateToProps = state => ({
    profileDetails: state.profileDetails
});
const mapDispatchToProps = dispatch => ({
    updateProfileDetails: id => dispatch(updateProfileDetails(id))
});

class LoginModal extends Component {

    // clientid = process.env.REACT_APP_CLIENTID
    // clientsecret = process.env.REACT_APP_CLIENTSECRETE
    clientid = "86wxrd833bz1cd"
    clientsecret = "QaQTlzKQxHidm94v"


    state = {
        isLoggedIn: false,
        userID: '',
        name: '',
        email: '',
        picture: '',
        code: '',
        errorMessage: '',

        signUpForm: {
            email: {
                label: 'Email',
                elementType: 'input',
                elementConfig: {
                    type: 'text'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                invalidMessage: '',
                touched: false
            },
            password: {
                label: 'Password',
                elementType: 'input',
                elementConfig: {
                    type: 'password'
                },
                value: '',
                validation: {
                    required: true,
                    // maxLength: 6,
                    // minLength: 6
                },
                valid: false,
                invalidMessage: '',
                touched: false
            }
        },
        formIsValid: false,
        hasAPIStatus: false,
        hasAPIMsg: '',
        loading: false
    }

    handler = {

        toggle: () => !this.props.isOpen,

        toggleSignUpModal: () => {
            this.props.closed();
            this.props.toggleSignUpModal();
        },

        toggleForgotPasswordModal: () => {
            this.props.closed();
            this.props.toggleForgotPasswordModal();
        },

        onSubmit: (event) => {
            event.preventDefault();
            const formData = {};
            for (let formElementIdentifier in this.state.signUpForm) {
                formData[formElementIdentifier] = this.state.signUpForm[formElementIdentifier].value;
            }
            if (formData) {
                let that = this;
                that.setState({ loading: true });
                return httpService.post("user/login/", formData)
                    .then(res => {
                        // console.log("user name ",res.data.user.first_name )
                        // debugger
                        if (res.data.success) {
                            this.setState({ hasAPIStatus: true, hasAPIMsg: res.data.msg, loading: false });
                            localStorage.setItem("__vz__token", res.data.token);
                            localStorage.setItem("__vz__userID", res.data.user.id);
                            localStorage.setItem("__vz__user", res.data.user.first_name);
                            let payload = {};
                            payload.profileDetails = res.data.user;
                            this.props.updateProfileDetails(payload);
                            setTimeout(function () {
                                that.props.closed();
                                that.props.history.push(`/`);
                            }, 1000);
                            window.location.reload();
                        } else {
                            this.setState({
                                hasAPIStatus: true,
                                hasAPIMsg: res.data.error.non_field_errors[0]
                            });
                        }
                    }).catch(error => {
                        console.log(error);
                        this.setState({ hasAPIStatus: true, hasAPIMsg: 'email or password not valid', loading: false });
                    });
            }

        },
        inputChanged: (event, inputIdentifier) => {
            const checkValid = checkValidity(event.target.value, this.state.signUpForm[inputIdentifier].validation);
            const updatedFormElement = updateObject(this.state.signUpForm[inputIdentifier], {
                value: event.target.value,
                valid: checkValid.isValid,
                invalidMessage: checkValid.message,
                touched: true
            });
            const updatedSignUpForm = updateObject(this.state.signUpForm, {
                [inputIdentifier]: updatedFormElement
            });

            let formIsValid = true;
            for (let inputIdentifier in updatedSignUpForm) {
                formIsValid = updatedSignUpForm[inputIdentifier].valid && formIsValid;
            }
            this.setState({ signUpForm: updatedSignUpForm, formIsValid: formIsValid });
        },

        linkedinLogin: () => {
            // window.location.replace("https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86wxrd833bz1cd&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2FlinkedInLogIn&scope=r_liteprofile%20r_emailaddress")
        },

        handleLinkedInSuccess: async (data) => {

            data['url'] = window.location.origin + "/linkedin"

            let that = this;
            httpService.post("/user/verifyLinkedIn/", data)
                .then(res => {
                    console.log(res)
                    if (res.data.message === "user created successfully") {
                        this.setState({ hasAPIStatus: true, hasAPIMsg: res.data.msg, loading: false });
                        localStorage.setItem("__vz__token", res.data.token);
                        localStorage.setItem("__vz__user", res.data.first_name);
                        let payload = {};
                        payload.profileDetails = res.data.user;
                        this.props.updateProfileDetails(payload);
                        setTimeout(function () {
                            that.props.closed();
                            that.props.history.push(`/`);
                        }, 1000);
                        window.location.reload();
                    }
                    if (res.data.message === "duplicate user") {

                        httpService.post("user/login/", { email: res.data.email, password: "asd987987asd" })
                            .then(res => {
                                // console.log("user name ",res.data.user.first_name )
                                console.log(res)
                                if (res.data.success) {
                                    this.setState({ hasAPIStatus: true, hasAPIMsg: res.data.msg, loading: false });
                                    localStorage.setItem("__vz__token", res.data.token);
                                    localStorage.setItem("__vz__userID", res.data.user.id);
                                    localStorage.setItem("__vz__user", res.data.user.first_name);
                                    let payload = {};
                                    payload.profileDetails = res.data.user;
                                    this.props.updateProfileDetails(payload);
                                    setTimeout(function () {
                                        that.props.closed();
                                        that.props.history.push(`/`);
                                    }, 1000);
                                    window.location.reload();
                                } else {
                                    this.setState({
                                        hasAPIStatus: true,
                                        hasAPIMsg: res.data.error.non_field_errors[0]
                                    });
                                }
                            }).catch(error => {
                                console.log(error);
                                this.setState({ hasAPIStatus: true, hasAPIMsg: 'email or password not valid', loading: false });
                            });
                    }
                })
                .catch(console.log)



        },

        handleLinkedInFailure: (error) => {
            // this.setState({
            //     code: '',
            //     errorMessage: error.errorMessage,
            // });
            console.log(error)
        },

        facebookResponse: (e) => {

            this.setState({ loading: true });
            this.setState({
                isLoggedIn: true,
                userID: e.userID,
                name: e.name,
                email: e.email,
                picture: e.picture,


            });

            let that = this

            httpService.post("/user/verifyFaceBook/", e)
                .then(res => {
                    console.log(res)
                    if (res.data.message === "user created successfully") {
                        this.setState({ hasAPIStatus: true, hasAPIMsg: res.data.msg, loading: false });
                        localStorage.setItem("__vz__token", res.data.token);
                        localStorage.setItem("__vz__user", res.data.first_name);
                        let payload = {};
                        payload.profileDetails = res.data.user;
                        this.props.updateProfileDetails(payload);
                        setTimeout(function () {
                            that.props.closed();
                            that.props.history.push(`/`);
                        }, 1000);
                        window.location.reload();
                    }
                    if (res.data.message === "duplicate user") {

                        httpService.post("user/login/", { email: res.data.email, password: "asd987987asd" })
                            .then(res => {
                                // console.log("user name ",res.data.user.first_name )
                                console.log(res)
                                if (res.data.success) {
                                    this.setState({ hasAPIStatus: true, hasAPIMsg: res.data.msg, loading: false });
                                    localStorage.setItem("__vz__token", res.data.token);
                                    localStorage.setItem("__vz__userID", res.data.user.id);
                                    localStorage.setItem("__vz__user", res.data.user.first_name);
                                    let payload = {};
                                    payload.profileDetails = res.data.user;
                                    this.props.updateProfileDetails(payload);
                                    setTimeout(function () {
                                        that.props.closed();
                                        that.props.history.push(`/`);
                                    }, 1000);
                                    window.location.reload();
                                } else {
                                    this.setState({
                                        hasAPIStatus: true,
                                        hasAPIMsg: res.data.error.non_field_errors[0]
                                    });
                                }
                            }).catch(error => {
                                console.log(error);
                                this.setState({ hasAPIStatus: true, hasAPIMsg: 'email or password not valid', loading: false });
                            });
                    }
                })
                .catch(console.log)







        },


        //componentClicked : ()=> console.log("clicked"),

    }


    render() {
        const { code, errorMessage } = this.state;
        const formElementsArray = [];
        for (let key in this.state.signUpForm) {
            formElementsArray.push({
                id: key,
                config: this.state.signUpForm[key]
            });
        }
        let form = (
            <form onSubmit={this.handler.onSubmit}>
                {formElementsArray.map(formElement => (
                    <Input
                        key={formElement.id}
                        label={formElement.config.label}
                        wrapperClass={formElement.config.wrapperClass}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        invalidMessage={formElement.config.invalidMessage}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={(event) => this.handler.inputChanged(event, formElement.id)} />
                ))}

                {this.state.hasAPIStatus ?
                    <p className={[errorClasses.InvalidText, "mt-2", "mb-2"].join(" ")}>
                        {this.state.hasAPIMsg}
                    </p>
                    : ''
                }
                <Loader loading={this.state.loading} />
                <Button className={classes.Jion} disabled={!this.state.formIsValid}>Login</Button>
                <p className={classes.OR}>OR</p>
                {/* <Button onClick={this.handler.facebookResponse} className={classes.logassocial}> Continue with Facebook</Button> */}
                <LinkedIn
                    clientId={this.clientid}
                    onFailure={this.handler.handleLinkedInFailure}
                    onSuccess={this.handler.handleLinkedInSuccess}
                    scope='r_liteprofile r_emailaddress'
                    redirectUri={`${window.location.origin}/linkedin`}
                    className="btn-fix"

                >
                    <Button className={classes.logassocial} title="linkedin login" alt="linkedin" >Continue with Linkedin</Button>
                </LinkedIn>

                <p className={[classes.Signin, 'mb-2'].join(" ")}>Don't have an account? <span onClick={this.handler.toggleSignUpModal}> Join VideoBzz </span></p>
                <p className={classes.Signin}>Forgot password? <span onClick={this.handler.toggleForgotPasswordModal}> Click here </span></p>
            </form>
        );
        return (
            <>

                <Modal
                    size="md"
                    isOpen={this.props.isOpen}
                    backdrop={true}
                    fade={false}
                    toggle={this.handler.toggle}
                    contentClassName={classes.Test} >
                    <ModalHeader className={classes.MHeader} toggle={this.props.closed}>

                    </ModalHeader>
                    <ModalBody className={classes.ModalBody}>
                        <Col sm="12">
                            <Loader loading={this.props.loading} />
                            {form}

                            {!this.state.isLoggedIn &&

                                <FacebookLogin
                                    appId="2719638398307763"
                                    fields="name,email,picture"
                                    callback={this.handler.facebookResponse}
                                    cssClass={classes.logassocial}
                                    icon=" fa-facebook " />
                            }




                        </Col>


                    </ModalBody>
                    {/* <LinkedInPage /> */}

                </Modal>

            </>


        );
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginModal));
