function isLoggedIn() {
    return localStorage.getItem('__vz__token');
}
function getUserId() {
    return localStorage.getItem('__vz__userID');
}
function logout() {
    localStorage.removeItem('__vz__token');
    localStorage.removeItem('__vz__userID');
    localStorage.removeItem('__vz__user');
}


export default {
    isLoggedIn,
    getUserId,
    logout
}