

import React from 'react';
import httpService from 'services/httpService';
import {
  Row,
  Col
} from 'reactstrap';

import classes from './Upload.module.css';
import SearchHeader from 'components/Gallery/SearchHeader/SearchHeader';
import UserProfileImg from 'components/UI/UserProfileImg/UserProfileImg';
import Vimeo from 'assets/contriupload/Vimeologo.png';
import Uploadfile from 'assets/contriupload/uploadfile.png';
import Sign from 'assets/contriupload/Sign.png';
import Wmap from 'assets/contriupload/worldmap.png';
import Axios from 'axios';


class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      player: null
    };

  }

  onChangeHandler = (event) => {
    console.log(event)
    this.handleSubmit(event.target.files[0])

  };

  handleSubmit = (selectedFile) => {
    // debugger
    const formData = new FormData();
    formData.append('inputFile', selectedFile);
    httpService.post('/gallery/upload/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        console.log('id', res.data.split("/")[2]);
        this.setState({ player: res.data.split("/")[2] })
      }).catch(error => {
        console.log(error);
      });

  };



  render() {
    return (

      <div className={classes.Upload}>

        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <SearchHeader isSearchHederTextHide={true} isRoyaltyFreeTextHide={true} />
        </Col>

        <div className={classes.UploadContent}>
          <p> 100% control over your content distribution via your Vimeo account!</p>
          <p> You will never have a storage issue! </p>
          <p> Whatever your Vimeo account can store, we can distribute it on VimeoBzz! </p>
          <p> Immediate access without long upload time as long your content is stored on your Vimeo account! </p>
          <p> Connect your Vimeo account and start sharing your content right away on VideoBzz! </p>
        </div>





        <div className={classes.UploadArea}>
          <Row className={classes.Norow}>
            <Col sm="12" md={{ size: 4 }} className="text-xl-right" >
              <div className={classes.UploadProfile}>
                <UserProfileImg />
                <p className={classes.LiorName}> Lior Bazak</p>
              </div>
            </Col>
            <Col sm="12" md={{ size: 4 }}  >
              <div className={classes.ConnectVimeo}>
                <p className={classes.Mainp}>Connect your <img src={Vimeo} alt="IMG" className="img-fluid" /> account  <div className={classes.UploadBtn}>
                  <button className={classes.MyBtn}> <img src={Uploadfile} alt="IMG" className="img-fluid" />  </button>
                  <input type="file" name="myfile" />
                </div><img src={Sign} alt="IMG" className="img-fluid" /> </p>
                <p className={classes.Italicline}>Unlimited content integration directely from your vimeo account! </p>
              </div>

              <p className={classes.Or}>OR</p>

              <div>
                {
                  !this.state.player ?
                    <div className={classes.UploadBtn}>
                      <form onSubmit={this.handleSubmit} >
                        <button className={classes.MyBtn}> Upload <img src={Uploadfile} alt="IMG" className="img-fluid" />  </button>
                        <input type="file" name="file_data" onChange={this.onChangeHandler} />
                      </form>
                    </div> :
                    <>
                      <h2>Video Uploaded successfully, video available <a href={`https://player.vimeo.com/video${this.state.player}`} target="_blank" >here</a></h2>
                      <button className={classes.MyBtn} onClick={() => window.location.reload()}> Upload More </button>
                    </>

                }

                <p className={classes.Italicline}>Weekly remain upload space - 15.74GB <img src={Sign} alt="IMG" className="img-fluid ml-2" /></p>

              </div>
            </Col>

          </Row>
        </div>


      </div>
    );
  }
}


export default Upload;




