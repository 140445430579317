import React, { Component } from 'react';
import {
    Row, Col, Button, Modal, ModalHeader, ModalBody,
    Input, Label
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import classes from './CustomModals.module.css';
import UserProfileImg from 'components/UI/UserProfileImg/UserProfileImg';
import Loader from 'components/UI/Loader/Loader';
import httpService from 'services/httpService';

let setFirstResolutions = {};

class FreeDownloadModal extends Component {
    constructor(props) {
        super(props);

        setFirstResolutions = (this.props.video.resolutions && this.props.video.resolutions[0]) ? this.props.video.resolutions[0] : {};

        this.state = {
            width: setFirstResolutions ? setFirstResolutions.width : '',
            height: setFirstResolutions ? setFirstResolutions.height : '',
            quality: setFirstResolutions ? setFirstResolutions.quality : '',
            loading: false
        }

        this.handler.downloadVideo = this.handler.downloadVideo.bind(this);
    }

    handler = {
        toggle: () => !this.props.isOpen,

        downloadVideo: () => {
            if (this.props.video.id) {
                this.setState({ loading: true });
                let endpoint = 'gallery/' + this.props.video.id + '/download/?';
                endpoint += 'quality=' + this.state.quality;
                endpoint += '&width=' + this.state.width;
                endpoint += '&height=' + this.state.height;
                let that = this;
                httpService.get(endpoint).then(function (response) {
                    that.setState({ loading: false });
                    if (response.data && response.data.link) {
                        window.location.href = response.data.link;
                        setTimeout(function () {
                            that.props.closed(true);
                        }, 3000);
                    }
                    // console.log(response);
                }).catch(function (error) {
                    that.setState({ loading: false });
                    console.log(error);
                })
            }
        },

        setDownloadFile: (quality, width, height) => {
            this.setState({
                quality: quality,
                width: width,
                height: height
            });
        }
    }

    render() {
        const resolutionOptions = this.props.video.resolutions ? this.props.video.resolutions.map((video, i) => {
            let checked = "";
            if (i === 0) {
                checked = true;
            }
            return <div key={"video_resolution_radio_" + i}>
                <Label
                    check
                    onClick={() => this.handler.setDownloadFile(video.quality, video.width, video.height)}>
                    <Input
                        type="radio"
                        name="video_resolution"
                        defaultChecked={checked} />{' '} {video.quality}, {video.width} x {video.height}
                </Label>
            </div>

        }) : "";

        let videoStyle = {}, ration = '0', div_col_size = 10, div_col_offset = 1;
        if (setFirstResolutions) {
            let width = setFirstResolutions.width;
            let height = setFirstResolutions.height;

            if (height > width) {
                ration = (width / height) * 100;
                div_col_size = 6;
                div_col_offset = 3;
            } else {
            }
            ration = (height / width) * 100;
            videoStyle.paddingTop = ration + '%';
        }

        return (

            <Modal
                size={this.props.modalSize ? this.props.modalSize : "lg"}
                isOpen={this.props.isOpen}
                backdrop={false}
                fade={false}
                toggle={this.handler.toggle}
                className={classes.FreeDownloadModal}
                contentClassName={classes.Test} >

                <ModalHeader className={classes.MHeader} toggle={() => this.props.closed()}>
                    <UserProfileImg />
                    <p className={classes.ProfilePopname}> Lior Bazak </p>
                    {/* <Button className={classes.BtnFreeDown}
                        onClick={() => this.handler.downloadVideo()}
                    >Free Download</Button> */}
                    <Row className={classes.ModalNav}>
                        <p>Follow</p>
                        <p>Donate</p>
                        <p> <FontAwesomeIcon icon={faThumbsUp}></FontAwesomeIcon> Likes</p>
                    </Row>
                </ModalHeader>
                <ModalBody className={classes.ModalBody}>

                    <Col sm="12" md={{ size: div_col_size, offset: div_col_offset }} className={["text-center", "p-0"].join(" ")}>
                        <div className="embed-responsive" style={videoStyle}>
                            <video
                                className="embed-responsive-item"
                                controls
                                autoPlay
                                id={"fdp_" + this.props.video.video_id}
                                poster={this.props.video.image}
                                src={(this.props.video.resolutions && this.props.video.resolutions.length > 0) ? this.props.video.resolutions[0].file_link : ""}
                            />
                        </div>

                    </Col>
                    <div><Button className={classes.BtnFreeDown}
                        onClick={this.handler.downloadVideo}>Free Download</Button>
                    </div>
                    <Col sm="12" md={{ size: 6, offset: 4 }} className="position-relative">
                        <Loader loading={this.state.loading} />
                        <div className={classes.RadioBtn}>
                            {resolutionOptions}
                        </div>

                    </Col>

                    <Col sm="12" md={{ size: 12 }}>
                        <p className={classes.SponsorAd}>Sponsor ad space</p>
                    </Col>

                </ModalBody>
            </Modal>

        );
    }
}


export default FreeDownloadModal;
