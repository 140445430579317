import React, { useState } from 'react';
import { Nav } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import classes from './NavItems.module.css';
import NavItem from './NavItem';
import NavItemProfile from './NavItemProfile';
import Menumsg from 'assets/msgmenu.png';
import LoginModal from 'components/Auth/LoginModal';
import SignUpModal from 'components/Auth/SignUpModal';
import LogoutModal from 'components/Auth/LogoutModal';
import ForgotPasswordModal from 'components/Auth/ForgotPasswordModal';
import siteLogo from 'assets/VideoBzz-Logo.png'

import Notification from 'components/Notification/Notification';
import authService from 'services/authService';

const NavItems = (props) => {

  const [loginModal, showLoginModal] = useState(false);
  const [signUpModal, showSignUpModal] = useState(false);
  const [isLoggedIn, updateLoggedIn] = useState(authService.isLoggedIn());
  const [logoutModal, showLogoutModal] = useState(false);
  const [forgotPasswordModal, showForgotPasswordModal] = useState(false);

  //console.log("is logged in  ",isLoggedIn)

  let navItemsClass = [];
  navItemsClass.push(classes.NavItems);
  navItemsClass.push("");
  navItemsClass = navItemsClass.join(" ");

  const toggleLoginModal = () => {
    updateLoggedIn(authService.isLoggedIn());
    showLoginModal(!loginModal);
  }
  //console.log("log in modal ",toggleLoginModal);
  const toggleSignUpModal = () => showSignUpModal(!signUpModal);
  const toggleLogoutModal = () => {
    // logOut();
    showLogoutModal(!logoutModal);
  }
  const toggleForgotPasswordModal = () => showForgotPasswordModal(!forgotPasswordModal);

  const logOut = () => {
    updateLoggedIn(null);
    toggleLogoutModal();
    authService.logout();
    window.location.reload();
    // isLoggedIn = null;
  }

  return (
    <React.Fragment>
      <Nav className={navItemsClass} navbar>
        <NavItem link='/'> <img src={siteLogo} alt="LOGO" /></NavItem>
        <NavItem link='/about'>About</NavItem>
        <NavItem link='/FaqClient'>FAQ</NavItem>
        <NavItem link='/blog'>Blog</NavItem>
        <NavItem link='/collaborations'>Collaborations</NavItem>
      </Nav>
      <Nav className={classes.NavItemsIcon}>
        <NavItem link='/'><div className={classes.Navimg}><img src={Menumsg} alt="Social" /></div></NavItem>
        <Notification customClass={classes.Navimg} />

      </Nav>
      <Nav className={navItemsClass} navbar>
        <NavItem link='/license' >License</NavItem>
        <NavItem link='/upload'>Upload <span>|</span></NavItem>
        {
          isLoggedIn ?
            <NavItem clicked={toggleLogoutModal} >Log Out</NavItem>
            :
            <NavItem clicked={localStorage.removeItem('__vz__picture'),localStorage.removeItem('__vz__name'),toggleLoginModal}>Login</NavItem>
            
        }
        <NavItem link='/like'><FontAwesomeIcon icon={faHeart}></FontAwesomeIcon> </NavItem>
        <NavItemProfile isLoggedIn={isLoggedIn} />
      </Nav>
      {loginModal ?
        <LoginModal
          isOpen={loginModal}
          closed={toggleLoginModal}
          toggleSignUpModal={toggleSignUpModal}
          toggleForgotPasswordModal={toggleForgotPasswordModal}
        />
        : ''}
      {signUpModal ?
        <SignUpModal
          isOpen={signUpModal}
          closed={toggleSignUpModal}
          toggleLoginModal={toggleLoginModal}
        />
        : ''}
      {forgotPasswordModal ?
        <ForgotPasswordModal
          isOpen={forgotPasswordModal}
          closed={toggleForgotPasswordModal}
          toggleLoginModal={toggleLoginModal}
        />
        : ''}
      {logoutModal ?
        <LogoutModal
          isOpen={logoutModal}
          closed={toggleLogoutModal}
          logout={logOut}
        />
        : ''}
    </React.Fragment>
  )
}
export default NavItems
