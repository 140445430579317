import React, { useEffect, useState } from "react";
import { Col, Container, Button, Input, Label, Row, Table } from "reactstrap";
import httpService from "services/httpService";
import classes from "./MetaInfoHelper.module.css";
import Enjoy from "assets/Enjoy.png";

export default function MetaInfoHelper({ selectedList }) {
  const [categories, setCategories] = useState([]);
  const [methods, setMethods] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [environments, setEnvironments] = useState([]);
  const [values, setValues] = useState([]);

  const [category, setCategory] = useState(-1);
  const [subCategory, setSubCategory] = useState(-1);
  const [method, setMethod] = useState(-1);
  const [language, setLanguage] = useState(-1);
  const [environment, setEnvironment] = useState(-1);
  const [value, setValue] = useState(-1);

  const header_category = "Video Product General Category";
  const header_sub_category = "Video Sub Category";
  const header_creative_method = "Video Creative Method";
  const header_language = "Video Language";
  const header_environment = "Video Enviornment";
  const header_currencies = "Video Values Symbols";

  const meta_map = {
    "creative-methods": [setMethods, methods, header_creative_method, category],
    languages: [setLanguages, languages, header_language, language],
    environments: [
      setEnvironments,
      environments,
      header_environment,
      environment,
    ],
    values: [setValues, values, header_currencies, value],
    categories: [setCategories, categories, header_category, category],
  };
  const field_map = {
    [header_category]: [category, setCategory],
    [header_sub_category]: [subCategory, setSubCategory],
    [header_creative_method]: [method, setMethod],
    [header_language]: [language, setLanguage],
    [header_environment]: [environment, setEnvironment],
    [header_currencies]: [value, setValue],
  };

  useEffect(() => {
    Object.keys(meta_map).forEach((k) => {
      httpService
        .get(`gallery/${k}`)
        .then((res) => {
          meta_map[k][0]([...res.data]);
        })
        .catch((error) => {
          console.log(error);
        });
    });
    return () => {
      console.log("cleanup.");
    };
  }, []);
  // console.log(classes);

  const handleChange = (e) => {
    console.log(e.target);
    try {
      field_map[e.target.name][1](parseInt(e.target.value));
    } catch (e) {
      console.error(e);
      console.error(field_map);
    }
  };

  const genListBox = (categories, header) => {
    return (
      <div>
        {field_map[header][0] !== -1 && (
          <img style={{ position: "absolute" }} src={Enjoy} />
        )}

        <div className={classes.fixedBox}>
          <p>{header}</p>
          <div className={classes.grow}>
            <Container fluid={true} className={classes.container}>
              <div>
                {categories.map((c) => {
                  return (
                    <div key={c.id}>
                      <Label check>
                        <Input
                          id={c.title}
                          type='radio'
                          name={`${header}`}
                          value={c.id}
                          onChange={handleChange}
                          className={
                            field_map[header][0] === c.id
                              ? classes.Selected
                              : classes.Unselected
                          }
                        />
                        {c.title}
                      </Label>
                    </div>
                  );
                })}
              </div>
            </Container>
          </div>
        </div>
      </div>
    );
  };

  // console.log(categories);
  // console.log(categories[category]);

  const queueForPublish = () => {
    httpService
      .post(`gallery/publish/`, {
        publishables: selectedList,
        category: category,
        subCategory: subCategory,
        method: method,
        language: language,
        environment: environment,
        value: value,
      })
      .then((res) => {
        window.location.href = "/user/my-videos";
        console.log("done");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const chosen_categories = categories.filter((c) => c.id === category)[0];
  console.log(chosen_categories);
  return (
    <div>
      <div className={classes.flexible}>
        {genListBox(categories, header_category)}
        {chosen_categories &&
          genListBox(chosen_categories.sub_category || [], header_sub_category)}
        {genListBox(methods, header_creative_method)}
        <Col>{genListBox(languages, header_language)}</Col>
        <Col>{genListBox(environments, header_environment)}</Col>
        <Col>{genListBox(values, header_currencies)}</Col>
      </div>
      <Button
        style={{ height: "50px" }}
        disabled={
          !(
            category !== -1 &&
            subCategory !== -1 &&
            method !== -1 &&
            environment !== -1 &&
            language !== -1
          )
        }
        onClick={queueForPublish}
      >
        Publish
      </Button>
    </div>
  );
}
