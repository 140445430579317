import React from "react";

import {
  Col,
  Form,
  Input,
  Row,
  Button,
  Modal,
  Card,
  ModalBody,
  ModalHeader,
  CardImg,
  CardBody,
  CardText,
  CardColumns,
  CardImgOverlay,
} from "reactstrap";

import classes from "./SetVimeoAccount.module.css";
import httpService from "services/httpService";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
// import SearchInput from "components/UI/SearchInput/SearchInput";
import Enjoy from "assets/Enjoy.png";
import MetaInfoHelper from "./MetaInfoHelper";
import SearchInput, { createFilter } from "react-search-input";

const VIDEOS_PER_PAGE = 8;

const sortMethods = {
  alphabetical: "Alphabatical",
  date: "Date Added",
  duration: "Video Duration",
  last_user_action_event_date: "Last interacted",
  likes: "Likes",
  modified_time: "Last modified Time",
  plays: "Number of plays",
};

class setVimeoAccount extends React.Component {
  constructor(props) {
    super(props);
    const parsedQueryString = queryString.parse(props.location.search);
    if (parsedQueryString && parsedQueryString.code) {
      httpService
        .get("user/vimeo-setup/?code=" + parsedQueryString.code)
        .then((res) => {
          this.setState({
            videosList: res.data.data,
          });
          window.location.href = "/user";
        })
        .catch((error) => {
          console.log(error);
        });
    }
    console.log("Cosntructor");
    this.state = {
      videosList: [],
      showSelector: false,
      pageCount: 1,
      selectedList: {},
      vimeo_integrated: false,
      showMetaPicker: false,
      ascending: false,
      selectedPage: 0,
      sortMethod: "date",
      query: "",
    };
  }

  refreshData() {
    const sort_order = this.state.ascending ? "asc" : "desc";
    httpService
      .get(
        `user/vimeo-list/?per_page=${VIDEOS_PER_PAGE}&page=${
          this.state.selectedPage + 1
        }&direction=${sort_order}&sort=${this.state.sortMethod}&query=${
          this.state.query
        }`
      )
      .then((res) => {
        console.log(res.data);
        const pageCount =
          Math.floor(parseInt(res.data.total) / parseInt(res.data.per_page)) +
          1;

        this.setState({
          videosList: res.data.data,
          pageCount: pageCount,
          vimeo_integrated: true,
        });
      })
      .catch((error) => {
        console.error(`error=${error}`);
        this.setState({ vimeo_integrated: false });
      });
  }

  componentDidMount() {
    this.refreshData();
  }

  handlePageClick = (data) => {
    this.setState({ selectedPage: data.selected }, () => {
      this.refreshData();
    });
  };

  myMethod = () => {
    if (!this.state.vimeo_integrated) {
      httpService
        .get("user/vimeo-setup/")
        .then((res) => {
          window.location.href = res.data.vimeo_url;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.setState({
        showSelector: true,
      });
    }
  };

  toggleSelection = (video) => {
    if (this.state.selectedList[video.uri] === undefined) {
      this.state.selectedList[video.uri] = video;
    } else {
      delete this.state.selectedList[video.uri];
    }
    this.setState({ selectedList: { ...this.state.selectedList } });
  };

  searchUpdated = (e) => {
    this.setState({ query: e }, () => {
      this.refreshData();
    });
  };
  render() {
    const check_left = {
      float: "right",
      marginTop: "32px",
      marginLeft: "-5px",
      marginRight: "34px",
    };

    const genThumbnails = (videos) => {
      return videos.map((video) => {
        const card = (
          <Card key={this.state.videosList.indexOf(video)}>
            <CardImg
              top
              src={`${video.pictures.sizes[2].link}`}
              alt='thumbnail'
            />
            <CardImgOverlay>
              {video.uri in this.state.selectedList && (
                <img src={Enjoy} className={classes.cardBody}></img>
              )}
            </CardImgOverlay>
            <CardBody className={classes.cardBody}>
              <CardText>{video.name.substr(0, 30)}</CardText>
              <Row>
                {/* <div className={classes.cardControls}> */}
                <Col>
                  <CardText>
                    Created{" "}
                    {Math.trunc(
                      (Date.now() - Date.parse(video.created_time)) /
                        (24 * 3600 * 1000)
                    )}{" "}
                    days ago
                  </CardText>
                </Col>
                <Col>
                  <Button
                    className={classes.right}
                    onClick={() => this.toggleSelection(video)}
                  >
                    {this.state.selectedList[video.uri] === undefined
                      ? "Select"
                      : "Remove"}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        );

        return card;
      });
    };

    const queueForPublish = () => {
      this.setState({
        showMetaPicker: true,
      });
    };

    const toggleSortOrder = () => {
      this.setState({ ascending: !this.state.ascending }, () => {
        this.refreshData();
      });
    };

    const updateSort = (e) => {
      this.setState({ sortMethod: e.target.value }, () => {
        this.refreshData();
      });
    };

    const getSelector = () => {
      return (
        <div className={classes.selectionPreview}>
          <Row>
            <Col>
              <div className={classes.spacedOut}>
                <b>All videos</b>
              </div>
              <Row>
                <Input type='checkbox'></Input>
                <Input
                  className={classes.selectionPreviewSorter}
                  type='select'
                  onChange={updateSort}
                >
                  {Object.keys(sortMethods).map((sm) => {
                    return <option value={sm}>{sortMethods[sm]}</option>;
                  })}
                </Input>
                <button onClick={toggleSortOrder}>
                  {this.state.ascending ? (
                    <span>&#8593;</span>
                  ) : (
                    <span>&#8595;</span>
                  )}
                </button>
              </Row>
            </Col>
            <Col>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <SearchInput
                  className={("search-input", classes.selectionPreviewSearch)}
                  onChange={(e) => this.searchUpdated(e)}
                />
              </div>
            </Col>
          </Row>
          <hr></hr>
          <CardColumns className={classes.myColumns}>
            {this.state.videosList && genThumbnails(this.state.videosList)}
          </CardColumns>
          <ReactPaginate
            pageCount={this.state.pageCount}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            containerClassName={"pagination"}
            activeClassName={"active"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            onPageChange={this.handlePageClick}
          ></ReactPaginate>
        </div>
      );
    };

    return (
      <div className={classes.SetVimeoAccount}>
        <Col sm='12' md='12'>
          <div className={classes.LoginVimeo}>
            <span className={classes.LogVimeo}>
              <Button onClick={this.myMethod}>
                {!this.state.vimeo_integrated
                  ? "Integrate with Vimeo"
                  : "Manage Vimeo Integration"}
              </Button>
            </span>
          </div>
          {/* Anurag*/}
          <div className={classes.box}>
            {this.state.showSelector && getSelector()}
          </div>
          <div className={classes.MyVideos}>
            <Row>
              <Col>
                {this.state.videosList && (
                  <div className='mb-4'>
                    <p className={classes.Head}>
                      <span className='mr-3'>
                        Total Videos {this.state.videosList.length}{" "}
                      </span>{" "}
                    </p>
                  </div>
                )}
              </Col>
              <Col>
                {this.state.videosList && (
                  <div className='mb-4'>
                    <p className={classes.Head}>
                      <span className='mr-3'>
                        Selected Videos{" "}
                        {Object.keys(this.state.selectedList).length}{" "}
                      </span>{" "}
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </div>
          {this.state.showSelector && (
            <div className={classes.right}>
              <b>Next</b> To complete video selection{" "}
              <Button onClick={() => queueForPublish()}>Next</Button>
            </div>
          )}
          {this.state.showMetaPicker && (
            <Modal isOpen={this.state.showMetaPicker} size='xl'>
              <ModalHeader
                toggle={() =>
                  this.setState({ showMetaPicker: !this.state.showMetaPicker })
                }
              >
                <div className={classes.flexibleh}>
                  <p>Choose what do you promote</p>
                </div>
              </ModalHeader>
              <ModalBody>
                <MetaInfoHelper selectedList={this.state.selectedList} />
              </ModalBody>
            </Modal>
          )}
        </Col>
      </div>
    );
  }
}

export default setVimeoAccount;
