import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Layout from "hoc/Layout/Layout";
import GalleryBuilder from "containers/GalleryBuilder/GalleryBuilder";
import SeriesBuilder from "containers/SeriesBuilder/SeriesBuilder";
import PageBuilder from "containers/PageBuilder/PageBuilder";
import Upload from "components/Contributor/Upload/Upload";
import ChatBuilder from "containers/ChatBuilder/ChatBuilder";
import BlogBuilder from "containers/BlogBuilder/BlogBuilder";
import BlogDetailBuilder from "containers/BlogBuilder/BlogDetailBuilder";
import UserBuilder from "containers/UserBuilder/UserBuilder";
import MyProfile from "components/User/MyProfile/MyProfile";
import Collection from "components/User/Collection/Collection";
// import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

import {
  ABOUT,
  FAQCLIENT,
  COLLABORATIONS,
  LICENSE,
  FAQ,
  TUTORILAS,
  IMPRINT_AND_TERMS,
  CONTRIBUTOR_GUIDELINE,
  //FAQ_CONTRIBUTOR,
} from "./AppConstant";
import LinkedInAuth from "../components/Auth/LinkedInAuth";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";

class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Layout>
            <Switch>
              <Route
                path="/FaqClient"
                component={() => <PageBuilder type={FAQCLIENT} />}
              />
              <Route
                path="/about"
                component={() => <PageBuilder type={ABOUT} />}
              />
              <Route exact path="/blog" component={BlogBuilder} />
              <Route path="/blog/:slug" component={BlogDetailBuilder} />
              <Route
                path="/license"
                component={() => <PageBuilder type={LICENSE} />}
              />
              <Route
                path="/collaborations"
                component={() => <PageBuilder type={COLLABORATIONS} />}
              />
              <Route path="/faq" component={() => <PageBuilder type={FAQ} />} />
              {/* <Route
                path="/contributor-faq"
                component={() => <PageBuilder type={FAQ_CONTRIBUTOR} />}
              /> */}
              <Route
                path="/tutorials"
                component={() => <PageBuilder type={TUTORILAS} />}
              />
              <Route
                path="/imprint-and-terms"
                component={() => <PageBuilder type={IMPRINT_AND_TERMS} />}
              />
              <Route
                path="/contributor-guideline"
                component={() => <PageBuilder type={CONTRIBUTOR_GUIDELINE} />}
              />
              <Route exact path="/linkedin" component={LinkedInPopUp} />
              <Route path="/upload" component={Upload} />
              {/* <Route path="/linkedInLogIn" component={LinkedInAuth} /> */}
              <Route path="/series/:id" component={SeriesBuilder} />
              <Route path="/chat/:id" component={ChatBuilder} />
              <Route path="/user" component={UserBuilder} />
              <Route path="/my-profile" component={MyProfile} />
              <Route path="/collections" component={Collection} />
              <Route exact path="/" component={GalleryBuilder} />
            </Switch>
          </Layout>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
