import React from 'react';

import { Table, CustomInput } from 'reactstrap';

import classes from './MyVideos.module.css';
import VideoImg from 'assets/ContriAdmin/videoimg.png';
import Greenicon from 'assets/ContriAdmin/Greencheck.png';
import Grayicon from 'assets/ContriAdmin/Icongray.png';


const myVideos = (props) => {

  return (
    <div className={classes.MyVideos}>
      <div className="mb-4"><p className={classes.Head}><span className="mr-3">Total Videos 24   </span>     /   <span className="ml-3">  Total Seires 4</span></p></div>
      <Table striped className={classes.MyvideoTB}>
        <thead>
          <tr>
            <th> </th>
            <th className="text-right">Video Name </th>
            <th>Single / Series</th>
            <th>Upload Data</th>
            <th>Views</th>
            <th>Downloads</th>
            <th>Earning</th>
            <th>On/Offline</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row"> <img src={VideoImg} className="img-fluid" alt="IMG" /> </td>
            <td> video name</td>
            <td>
              <span className={classes.GreenIcon}> <img src={Greenicon} className="img-fluid" alt="IMG" /></span>
              <span className={classes.GrayIcon}> <img src={Grayicon} className="img-fluid" alt="IMG" /></span>
            </td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>
              <CustomInput type="switch" id="exampleCustomSwitch1" name="customSwitch" className={classes.MySwitch} />
            </td>
            <td>Jacob</td>
            <td>Thornton</td>
            <td><i className="far fa-trash-alt"></i></td>
          </tr>
          <tr>
            <td scope="row"> <img src={VideoImg} className="img-fluid" alt="IMG" /> </td>
            <td> video name</td>
            <td>
              <span className={classes.GreenIcon}> <img src={Greenicon} className="img-fluid" alt="IMG" /></span>
              <span className={classes.GrayIcon}> <img src={Grayicon} className="img-fluid" alt="IMG" /></span>
            </td>
            <td>Thornton</td>
            <td>@fat</td>
            <td>
              <CustomInput type="switch" id="exampleCustomSwitch2" name="customSwitch" className={classes.MySwitch} />
            </td>
            <td>Jacob</td>
            <td>Thornton</td>
            <td><i className="far fa-trash-alt"></i></td>
          </tr>
          <tr>
            <td scope="row"> <img src={VideoImg} className="img-fluid" alt="IMG" /> </td>
            <td> video name</td>
            <td><span className={classes.GrayIcon}> <img src={Grayicon} className="img-fluid" alt="IMG" /></span>  <span className={classes.GreenIcon}> <img src={Greenicon} className="img-fluid" alt="IMG" />  View Video of this Series </span>
            </td>
            <td>the Bird</td>
            <td>@twitter</td>
            <td>
              <CustomInput type="switch" id="exampleCustomSwitch3" name="customSwitch" className={classes.MySwitch} />
            </td>
            <td>Jacob</td>
            <td>Thornton</td>
            <td><i className="far fa-trash-alt"></i></td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}


export default myVideos;
